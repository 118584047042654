<template>
  <div class="mod-config">
    <el-card
      body-style="padding-bottom: 0;"
      style="margin-top: -5px; min-height: 670px;"
	  height="500px"
    >
      <div class="title">分现场进度情况</div>
	  <div class="line" style="margin-bottom:0px"></div>
      <div v-for="(scene, index) in dataList" :key="index">
		  <el-row class="name_set">
       		 <div style="margin:15px">{{ scene.sceneName }}</div>
			</el-row>
			<el-table max-height="400" :row-class-name="tableRowClassName" :span-method="(param)=>rowSpanMethod(param, index)"
				ref="dataListTable" class="dataListTable" border :data="scene.contentList"
				header-align="center" header-cell-class-name="dataListTHeader" :cell-style="{ padding: '0px' }">
				<el-table-column v-for="(v, idx) in scene.headers" :key="idx" v-if="scene.headers != undefined && scene.headers.length > 0 && idx == 0"
					:label="v.nodeName" :prop="v.nodeId" align="center" width="60px">
					 <template slot-scope="scope">
						<span style="font-weight:bolder">
							{{scope.row.title }}
						</span>
					 </template>
				</el-table-column>
				<el-table-column v-for="(v, idx) in scene.headers" :key="idx" v-if="scene.headers != undefined && scene.headers.length > 0 && idx > 0"
					:label="v.nodeName" :prop="v.nodeId" align="center">
					 <template slot-scope="scope">
						<span v-if="!getDisabled(scope.row.recNo)">
							{{scope.row.recContentList[idx-1].colValue }}
						</span>
						<el-input v-if="getDisabled(scope.row.recNo)" class="sp-input"
							v-model="scope.row.recContentList[idx-1].colValue"></el-input>
					 </template>
				</el-table-column>
				<el-table-column align="left" label="" v-if="scene.headers != undefined && scene.headers.length == 1">
				</el-table-column>
				<el-table-column align="left" label="" width="130px" v-if="scene.headers != undefined && scene.headers.length > 1">
					<template slot-scope="scope">
						<el-button type="text" size="small" v-if="!updateFlg && !addFlg"
							@click="updateHandle(scope.row.recNo)">修改</el-button>
						<el-button type="text" size="small" v-if="!updateFlg && !addFlg && scope.row.recType === '0'"
							@click="addChangeHandle(scene.sceneId)">添加变更</el-button>
						<el-button type="text" size="small" v-if="!updateFlg && !addFlg && scope.row.recType=== '1'"
							@click="deleteHandle(scope.row.recNo)">删除</el-button>
						<!--新增变更时按钮-->
						<el-button v-preventReClick type="text" size="small"
							@click="saveRecordHandle(scope.row, scene.sceneId)"
							v-if="getDisabled(scope.row.recNo) && addFlg">保存</el-button>
						<el-button v-preventReClick type="text" size="small"
							@click="cancelSaveHandle()"
							v-if="getDisabled(scope.row.recNo) && addFlg">取消</el-button>	
						<!--修改时按钮-->
						<el-button v-preventReClick type="text" size="small"
							@click="updateRecordHandle(scope.row)"
							v-if="getDisabled(scope.row.recNo) && updateFlg">保存</el-button>
						<el-button v-preventReClick type="text" size="small"
							@click="cancelUpdateHandle()"
							v-if="getDisabled(scope.row.recNo) && updateFlg">取消</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
    </el-card>
  </div>
</template>
<script>
import $common from "@/utils/common.js"
export default {
  data() {
    return {
      pageSpan: 12,
	  addFlg : false,
      updateFlg: false,
      form: {},
      workNo: $common.getItem("workNo"),
      dataList: [],
      sceneIds: [],
      list: [],
	  updateRecNo:'',
    };
  },
  components: {
  },
  mounted() {
    // this.getDataList();
	this.refreshSceneRecords();
  },
  methods: {
	getDisabled(val) {
		if (this.updateRecNo === val && this.updateFlg) {
			return true;
		} else if(val === '' && this.addFlg){
			return true;
		}
		return false;
	},
	updateHandle(val) {
		this.updateRecNo = val
		this.updateFlg = true;
	},
	cancelUpdateHandle() {
		this.updateFlg = false;
		this.updateRecNo = ''
		this.getDataList()
	},
	addChangeHandle(sceneId) {
		this.addFlg = true;
		// 获取当前现场变更数据最大排序值并当前节点组装数据列表
		var maxRecSort = 0;
		var recContentList = [];
		for (var i = 0; i < this.dataList.length; i++) {
			if(this.dataList[i].sceneId === sceneId) {
				maxRecSort = this.dataList[i].maxRecSort;
				for (var j = 0; j < this.dataList[i].headers.length; j++) {
					if(this.dataList[i].headers[j].nodeId != 0) {
						let content = {
							colName: this.dataList[i].headers[j].nodeId,
							colValue: ''
						}
						recContentList.push(content);
					}
				}
				let changeData = {
					recNo: '',
					title: '变更',
					recType: '1',
					recSort: maxRecSort + 1,
					recContentList : recContentList
				}
				// 插入数据
				var contentList = [];
				for (var m = 0; m < this.dataList[i].contentList.length; m++) {
					if(this.dataList[i].contentList[m].recType === '0' ||
						this.dataList[i].contentList[m].recType === '1') {
						contentList.push(this.dataList[i].contentList[m]);
					} else {
						contentList.push(changeData);
						contentList.push(this.dataList[i].contentList[m]);
					}
				}
				this.dataList[i].contentList = contentList;
				this.dataList[i].maxRecSort = maxRecSort + 1;
				break;
			}
		}
	},
	cancelSaveHandle() {
		this.addFlg = false;
		this.getDataList();
	},
	deleteHandle(recNo){
		this.$http({
			url: this.$store.state.httpUrl + "/business/projectscenerecord/delete/" + recNo,
			method: 'post',
			params: this.$http.adornParams({
			})
		}).then(({
			data
		}) => {
			if (data && data.resultCode === 200) {
				this.getDataList();	
			}
		})
	},
	saveRecordHandle(val, sceneId) {
		let _url = "/business/projectscenerecord/insert";
		this.$http({
			url: this.$store.state.httpUrl + _url,
			method: "post",
			data: this.$http.adornData({
				sceneId: sceneId,
				workNo: $common.getItem("workNo"),
				recType: val.recType,
				recSort: val.recSort,
				recContentList: val.recContentList
			})
		}).then(({
			data
		}) => {
			if (data && data.resultCode === 200) {
				this.$message({
					message: "操作成功",
					type: "success",
					duration: 1500,
				});
				this.getDataList()
				this.addFlg = false
			}
		});
	},
	updateRecordHandle(val) {
		let _url = "/business/projectscenerecord/update";
		this.$http({
			url: this.$store.state.httpUrl + _url,
			method: "post",
			data: this.$http.adornData(val)
		}).then(({
			data
		}) => {
			if (data && data.resultCode === 200) {
				this.$message({
					message: "操作成功",
					type: "success",
					duration: 1500,
				});
				this.getDataList()
				this.updateFlg = false
				this.updateRecNo = '';
			}
		});
	},
	refreshSceneRecords() {
		this.$http({
			url: this.$store.state.httpUrl + "/business/projectscenerecord/refreshSceneRecords/" + $common.getItem("workNo"),
			method: 'post',
			params: this.$http.adornParams({
			})
		}).then(({
			data
		}) => {
			if (data && data.resultCode === 200) {
				this.getDataList();	
			}
		})
	},
    // 获取数据列表
    getDataList() {
      this.$http({
        url: this.$store.state.httpUrl + "/business/projectscenerecord/list/" + $common.getItem("workNo"),
        method: 'get',
        params: this.$http.adornParams({
        })
      }).then(({
        data
      }) => {
        if (data && data.resultCode === 200) {
          this.dataList = data.body.records;
        }
      })
    },
	tableRowClassName({ row, rowIndex }) {
		return $common.tableScnenRowClassName({ row, rowIndex });
	},
	flitterData (arr) {
      let spanOneArr = []
      let concatOne = 0
      arr.forEach((item, index) => {
        if (index === 0) {
          spanOneArr.push(1);
        } else {
          //name 修改
          if (item.title === arr[index - 1].title) { //第一列需合并相同内容的判断条件
            spanOneArr[concatOne] += 1;
            spanOneArr.push(0);
          } else {
            spanOneArr.push(1);
            concatOne = index;
          };
        }
      });
      return {
        one: spanOneArr,
      }
    },
	rowSpanMethod({row, column, rowIndex, columnIndex}, index) {
		if (columnIndex === 0) {
			// this.tableData  修改
			const _row = (this.flitterData(this.dataList[index].contentList).one)[rowIndex];
			const _col = _row > 0 ? 1 : 0;
			return {
			rowspan: _row,
			colspan: _col
			};
		}
		},
  }
};
</script>
<style scoped>
.sp-input /deep/ .el-input__inner {
    width: 100% !important;
}

.name_set {
	background-color: rgba(250, 250, 250, 1);
	border:solid 1px rgba(218, 218, 218, 1);
	margin-bottom: -2px;
}

.title {
	margin-bottom: 10px;
}
</style>
